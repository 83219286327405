* {
  font-family: 'easyjet-rounded-book', Verdana, sans-serif;
}

#new-bot-staging-banner {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 50%;
  background-color: aqua;
  padding: 5px;
}

#faq-main-app-container {
  margin: 0px;
  height: 100%;
}
body {
  margin: 0px;
}

#root {
  height: 100%;
}

#faq-app-container {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#faq-webchatRoot {
  height: 100%;
  display: flex;
}

#botonic-webchat {
  position: relative !important;
  right: auto;
  bottom: auto;
  width: 760px !important;
  min-height: 600px !important;
  height: 80% !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px !important;
}

@media only screen and (max-width: 780px) {
  body {
    background: none;
  }
  #faq-app-container {
    display: block;
    background-color: #ffffff;
    margin: 0px;
    width: 100%;
    height: 100% !important;
  }
  #faq-webchatRoot {
    height: 100%;

    #botonic-webchat {
      width: 100% !important;
      height: 100% !important;
      margin: 0px !important;
      border-radius: 0px !important;

      > div:first-child {
        border-radius: 0px;
      }
    }
  }
}

@media only screen and (max-height: 600px) {
  #faq-app-container {
    display: block;
    height: 100% !important;
  }
  #faq-webchatRoot {
    height: 100%;

    #botonic-webchat {
      height: 100% !important;
      min-height: auto !important;
      margin: 0px auto !important;
      border-radius: 0px !important;

      > div:first-child {
        border-radius: 0px;
      }
    }
  }
}

@media only screen and (min-width: 780px) {
  #faq-main-app-container {
    background: url('../background.jpg') repeat-x center center fixed;
    background-size: cover;
  }
}
